
import { defineComponent, ref, getCurrentInstance } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import JwtService from "@/core/services/JwtService";
import { updatePengguna } from "./servicePengguna";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "edit-user",
  emit: ["fetch-data"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props, { emit }) {
    const editSave = ref<HTMLElement | null>(null);
    const modalEditUser = ref<HTMLElement | null>(null);
    const instance = getCurrentInstance();
    const UserModel = ref({
      username: "",
      full_name: "",
      email: "",
      disabled: false,
      photo: "",
      role: "",
      _id: "",
    });
    const UserValidator = Yup.object().shape({
      fullname: Yup.string().required().label("Fullname"),
      username: Yup.string().required().label("Username"),
      role: Yup.string().required().label("Role"),
      email: Yup.string().email().required().label("Email"),
    });

    const showModal = (data) => {
      UserModel.value = data;
    };

    const editUser = async () => {
      if (editSave.value) {
        editSave.value.setAttribute("data-kt-indicator", "on");
        let loadout = UserModel.value;
        const result = await updatePengguna(loadout);
        if (result.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            toast: true,
            title: "Berhasil! Data pengguna berhasil diubah",
            showConfirmButton: false,
            timer: 3000,
          });

          emit("fetch-data");
          hideModal(modalEditUser.value);
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            toast: true,
            title: "Gagal! Terjadi kesalahan saat mengubah data pengguna",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        editSave.value?.removeAttribute("data-kt-indicator");
      }
    };

    return {
      editSave,
      UserValidator,
      editUser,
      showModal,
      UserModel,
      modalEditUser,
    };
  },
});
